import { vIntersectionObserver } from "@vueuse/components";
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";

export default function (el, checkAlways) {
  const isVisible = ref(false);
  const route = useRoute();

  // Used only for faster testing animations
  // checkAlways = true;

  const { visibility } = useIntersectionObserver(
    el,
    ([{ isIntersecting }], observerElement) => {
      if (
        isIntersecting ||
        Object.keys(route.query).includes("motion") ||
        checkAlways
      )
        isVisible.value = isIntersecting;
    }
  );

  return isVisible;
}
